@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
.zdkMain {
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  max-width: 90rem;
  margin: 0 auto;
  background-color: white;
  color: #2b2a29;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.zdkHeader {
  font-family: "Raleway", sans-serif;
  min-height: 50vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 75%;
  text-align: center;
 
}

.zdkHeader img{
  max-width: 30rem;
  height: auto;
}

.Footer {
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 31.25rem){
    display: block;
    padding: 0 1rem;
  }
}
.Location{
  padding: 0.3125rem;
  margin: 0;
}
.Footer p{
  display: flex;
  flex-direction: column;
}

.SocialMediaWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
}

.SocialMedia {
  padding: 0.3125rem 0 ;
}
.SocialMedia a {
  text-decoration: none;
}
.SocialMedia a:hover{
 opacity: 0.8;
}

.SocialMedia img{
  vertical-align: middle;
}
.SocialMedia span{
  line-height: 1.5rem;
  padding-left: 1rem;
  color: #2b2a29;
}
.Location{
  padding: 1rem;
}
.Location img {
  margin-left: -0.25rem;
}
.Location p{
  margin-top: 0.25rem;
}



